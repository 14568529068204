import Flex from "components/flex/Flex";
import SmallTitle from "components/title/SmallTitle";
import React from "react";
import styled from "styled-components";
import PriceTable from "./PriceTable";

const ConsignmentPrice = () => {
  return (
    <Container>
      <SmallTitle>위탁 매입</SmallTitle>
      <Flex row style={{ width: "100%" }}>
        <PriceTable assetType="GOLD" title="금" />
        <DivisionLine />
        <PriceTable assetType="SILVER" title="은" />
      </Flex>
    </Container>
  );
};
export default ConsignmentPrice;

const Container = styled(Flex)`
  flex: 1;
  padding: 10px;

  .sort-section {
    position: absolute;
    right: 0;
    height: 100%;
    justify-content: center;
  }

  .content-header {
    margin-top: 10px;
    justify-content: space-between;

    .header {
      width: 135px;
    }
    .data {
      min-width: 140px;
      white-space: nowrap;
      justify-content: center;
    }
  }
`;

const DivisionLine = styled.div`
border: 1px solid black;
margin: 0px 10px; 
height: 100%;
`;
