import React from "react";

const ConsignmentPurchaseStatus = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};
export default ConsignmentPurchaseStatus;
