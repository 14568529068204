import axios from "axios";
import { actionError } from "../redux/action/ActionActions";
import { signOut } from "../redux/auth/AuthReducer";
import { STORAGE_KEY } from "../libs/consts";
import { objToQueryStr } from "../services/utils";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_ADMIN_API_URL;

const commonInstance = axios.create({ headers: { Authorization: "" } });

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  function (error) {
    // 오류 응답을 처리
    // ...
    // eslint-disable-next-line no-undef
    const { store } = require("../redux/store");
    const status = error?.response?.status;

    if (status === 401 || typeof error.response === "undefined") {
      store.dispatch(
        actionError("인증 토큰이 만료되었습니다.\n다시 로그인해주세요.")
      );
      localStorage.removeItem("passwordVerification");
      localStorage.removeItem(STORAGE_KEY.accessToken);
      store.dispatch(signOut());

      // 401 에러 처리 후 나머지 처리 건너뛰기
      return Promise.reject(error);
    } else if (status === 403 || status === 422) {
      // 403 또는 422 Server에서 Error 메세지 제공
      store.dispatch(actionError(error));
    } else if (status !== 422) {
      // 422 제외 에러처리
      store.dispatch(actionError("알 수 없는 오류가 발생했습니다."));
    }

    return Promise.reject(error);
  }
);

export const setAuthorization = (authToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
};

//////////////////////////////////////////////////////////////////////////////////////////////////// USER API

export const getProcessingStatus = () => {
  return axios.get(`/api/v1/manager/statistics/dashboard/processing-status`);
};

export const getInquiryStatus = () => {
  return axios.get(`/api/v1/manager/statistics/dashboard/inquiry-status`);
};

export const getUserStatus = () => {
  return axios.get(`/api/v1/manager/statistics/dashboard/user-status`);
};

export const getUserFeeStatus = () => {
  return axios.get(`/api/v1/manager/statistics/dashboard/user-fee-status`);
};

export const getFranchiseeFeeStatus = () => {
  return axios.get(
    `/api/v1/manager/statistics/dashboard/franchisee-fee-status`
  );
};

export const getBuySellStatus = () => {
  return axios.get(`/api/v1/manager/statistics/status/buy-sell-status`);
};

export const getAppraisalStatus = () => {
  return axios.get(`/api/v1/manager/statistics/status/appraisal-status`);
};

export const getGoodsStatus = () => {
  return axios.get(`/api/v1/manager/statistics/status/goods-status`);
};

export const getSafeTradeStatus = () => {
  return axios.get(`/api/v1/manager/statistics/status/safe-trade-status`);
};

export const getTradeConfig = (query) => {
  return axios.get(`/api/v1/manager/shared/config${query}`);
};

export const getTerms = () => {
  return axios.get(`/api/v1/manager/shared/terms`);
};

export const getTermDetail = (type) => {
  return axios.get(`/api/v1/manager/shared/terms/${type}`);
};

export const getcurrentmarketprice = (types) => {
  return axios.get(
    `/api/v1/manager/market/buysell/currentmarketprice?type=${types}`
  );
};

export const getOrderDetail = (transId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/${transId}/getDetail`
  );
};

export const getDeliveryHistory = (transId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/${transId}/delivery`
  );
};

export const getProductOrderHistory = (transId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/transOrder/${transId}`
  );
};

export const postProductOrderMod = (param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/trans/transOrder`,
    param
  );
};

// 실물인출 상세 - 배송정보 수정
export const postDeilveryChange = (querys, param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/trans/${querys}/delivery`,
    param
  );
};

export const postProductOrderModPut = (querys, param) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/trans/transOrder${querys}`,
    param
  );
};

export const getAppraisalStatSummary = () => {
  return axios.get(`/api/v1/manager/market/appraisal/Stat/summary`);
};

export const getAppraisalStatStatus = (query) => {
  return axios.get(`/api/v1/manager/market/appraisal/Stat/status${query}`);
};

export const getGoodsCategoryList = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/goodsCategory`);
};

//라벨설정 조회
export const getGoodsLabelConfig = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/label-config`);
};

//라벨설정 생성
export const postGoodsLabelConfig = (data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/label-config`,
    data
  );
};

//라벨설정 수정
export const putGoodsLabelConfig = (id, data) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/label-config/${id}`,
    data
  );
};

//라벨설정삭제
export const deleteGoodsLabelConfig = (id) => {
  return axios.delete(
    `/api/v1/manager/market/goodswithdrawal/label-config/${id}`
  );
};

export const postGoodsCategoryModify = (param, id) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsCategory/${id}`,
    param,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const postGoodsCategoryCreate = (param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsCategory`,
    param,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const getGoodsTypeList = (categoryId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/goodsType/${categoryId}/list`
  );
};

export const postGoodsTypeDelete = (param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsType/delete`,
    param
  );
};

export const postGoodsTypeModify = (id, param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsType/${id}/modify`,
    param
  );
};

export const postGoodsTypeCreate = (id, param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsType/${id}/create`,
    param
  );
};
////////////////////////////////////////////////////////////////////////////////////////////////////

export const postSignIn = (param) => {
  return commonInstance.post("/api/v1/manager/auth", param);
};

// 계정 유효성 검사
export const postLoginCheck = (param) => {
  return commonInstance.post("/api/v1/manager/auth/check", param);
};

// OTP 생성
export const postCreateOTP = (param) => {
  return commonInstance.post("/api/v1/manager/auth/otp/qrcode", param);
};

// OTP 로그인
export const postLoginOtp = (param) => {
  return commonInstance.post("/api/v1/manager/auth/otp", param);
};

// OTP 초기화
export const deleteManagerOtp = (managerId) => {
  return axios.delete(`/api/v1/manager/admin/${managerId}/otp`);
};

export const searchAccount = (param) => {
  return axios.get(`/api/v1/manager/search${param}`);
};

export const searchMemberExcel = (param) => {
  return axios.get(`/api/v1/manager/search/excel${param}`, {
    responseType: "blob",
  });
};

export const searchShopExcel = (param) => {
  return axios.get(`/api/v1/manager/shop/excel${param}`, {
    responseType: "blob",
  });
};

export const searchUserDetail = (id) => {
  return axios.get(`/api/v1/manager/search/detail/${id}`);
};

export const searchUserStatusHistory = (id) => {
  return axios.get(`/api/v1/manager/user/${id}/status/history`);
};

export const searchUserLoginHistory = (id, data) => {
  return axios.get(`/api/v1/manager/user/${id}/login/history${data}`);
};

export const getUserAsset = (id) => {
  return axios.get(`/api/v1/manager/search/detail/${id}/asset`);
};

export const getUserTradeFee = (id) => {
  return axios.get(`/api/v1/manager/user/${id}/fee`);
};

export const putUserTradeFee = (id, param) => {
  return axios.put(`/api/v1/manager/user/${id}/fee`, param);
};

export const putUserStatus = (id, param) => {
  return axios.put(`/api/v1/manager/user/${id}/status`, param);
};

export const modifyUserDetail = (id, param) => {
  return axios.post(`/api/v1/manager/user/${id}/modify`, param);
};

export const postBankAccount = (id, param) => {
  return axios.post(`/api/v1/manager/user/${id}/bankaccount`, param);
};

export const modifyCanReSignupDate = (id, param) => {
  return axios.post(`/api/v1/manager/user/${id}/reset/resignupdate`, param);
};

export const getNotSignedSocialUser = (param) => {
  return axios.get(`/api/v1/manager/user_social/not_signup/search${param}`);
};

export const getNotSignedSocialUserSummary = (param) => {
  return axios.get(`/api/v1/manager/user_social/not_signup/summary${param}`);
};

/*****************************
 * 회원관리 > 매장 회원
 *****************************
 */

export const getCity = () => {
  return axios.get("/api/v1/manager/shop/city");
};

export const getShops = (param) => {
  return axios.get(`/api/v1/manager/shop${param}`);
};

export const getShopDetail = (id) => {
  return axios.get(`/api/v1/manager/shop/${id}`);
};

export const getBusinessCalendar = (id, param) => {
  return axios.get(`/api/v1/manager/shop/hours/${id}/${param}`);
};

export const deleteShop = (id) => {
  return axios.delete(`/api/v1/manager/shop/${id}`);
};

export const saveShop = (id, data) => {
  return axios.post(`/api/v1/manager/shop/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getBankList = () => {
  return axios.get(`/api/v1/manager/asset/bank?size=200`);
};

// 매장 등록
export const postCreateShop = (data) => {
  return axios.post(`/api/v1/manager/shop`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// 추천 대리점 목록
export const getRecommendShops = () => {
  return axios.get(`/api/v1/manager/shop/recommend`);
};

// 제휴사 목록
export const getAssociateShops = () => {
  return axios.get(`/api/v1/manager/shop/associate`);
};

// 지체상금 목록
export const getAppraisalPenalty = (param) => {
  return axios.get(`/api/v1/manager/market/appraisal/penalty${param}`);
};

// 지체상금 목록 > 상세
export const getAppraisalPenaltyDetail = (param) => {
  return axios.get(`/api/v1/manager/market/appraisal/summary_info${param}`);
};

export const postCreateProduct = (data) => {
  return axios.post(`/api/v1/manager/market/goodswithdrawal/goods`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const searchVirtualList = (query = "") => {
  return axios.get(`/api/v1/manager/asset/virtual${query}`);
};

export const searchVirtualListExcel = (query = "") => {
  return axios.get(`/api/v1/manager/asset/virtual/excel${query}`, {
    responseType: "blob",
  });
};

export const unAssignVirtualAccount = (data) => {
  return axios.delete(`/api/v1/manager/asset/virtual`, { data });
};

export const postVirtualAccount = (data) => {
  return axios.post(`/api/v1/manager/asset/virtual/081`, data);
};

export const putModifyProduct = (data, id) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goods/item/${id}`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};
//실물인출 > 제품관리 > 상품 이미지 생성
export const postRegisterProductImage = (id, data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goods/item/${id}/image`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};
//실물인출 > 제품관리 > 상품 이미지 순서변경
export const patchSortProductImage = (id, data) => {
  return axios.patch(
    `/api/v1/manager/market/goodswithdrawal/goods/item/${id}/image`,
    data
  );
};

//실물인출 > 제품관리 > 상품 이미지 삭제
export const deleteProductImage = (id, imageId) => {
  return axios.delete(
    `/api/v1/manager/market/goodswithdrawal/goods/item/${id}/image/${imageId}`
  );
};

export const getBannerList = (querys) => {
  return axios.get(`/api/v1/manager/banner${querys}`);
};

export const patchBannerList = (data) => {
  return axios.patch(`/api/v1/manager/banner`, data);
};

export const postCreateNotice = (data) => {
  return axios.post(`/api/v1/manager/notice`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getNoticeList = (querys) => {
  return axios.get(`/api/v1/manager/notice${querys}`);
};

export const getNoticeDetail = (noticeId) => {
  return axios.get(`/api/v1/manager/notice/${noticeId}`);
};

export const postNoticeStatus = (data) => {
  return axios.post(`/api/v1/manager/notice/status`, data);
};

export const postEditNotice = (data, noticeId) => {
  return axios.post(`/api/v1/manager/notice/${noticeId}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteImage = (noticeId, imageId) => {
  return axios.delete(`/api/v1/manager/notice/image/${noticeId}/${imageId}`);
};

export const getPopupList = (querys) => {
  return axios.get(`/api/v1/manager/popups${querys}`);
};

export const postRegisterPopup = (data) => {
  return axios.post(`/api/v1/manager/popups`, data);
};

export const patchStatusPopup = (data) => {
  return axios.patch(`/api/v1/manager/popups/status`, data);
};

export const getPopupDetail = (id) => {
  return axios.get(`/api/v1/manager/popups/${id}`);
};

export const patchEditPopup = (id, data) => {
  return axios.patch(`/api/v1/manager/popups/${id}`, data);
};

export const deleteFaqImage = (faqId, imageId) => {
  return axios.delete(`/api/v1/manager/faq/${faqId}/${imageId}`);
};

export const postRegisterFaqCategory = (data) => {
  return axios.post(`/api/v1/manager/faq/category`, data);
};

export const getFaqCategoryList = () => {
  return axios.get(`/api/v1/manager/faq/category`);
};

export const putChageStatusFaqCategory = (param) => {
  return axios.put(`/api/v1/manager/faq/category${param}`);
};

export const postChangePositionFaqCategory = (data) => {
  return axios.post(`/api/v1/manager/faq/category/position`, data);
};

export const getFaqList = (querys) => {
  return axios.get(`/api/v1/manager/faq${querys}`);
};

export const postFaqRegister = (data) => {
  return axios.post(`/api/v1/manager/faq`, data);
};

export const postFaqStatus = (data) => {
  return axios.post(`/api/v1/manager/faq/status`, data);
};

export const getFaqDetail = (id) => {
  return axios.get(`/api/v1/manager/faq/${id}`);
};

export const postEditFaq = (data) => {
  return axios.post(`/api/v1/manager/faq/modify`, data);
};

export const getQnaList = (querys) => {
  return axios.get(`/api/v1/manager/qna${querys}`);
};

export const getQnaSummary = () => {
  return axios.get(`/api/v1/manager/qna/summary`);
};

export const getQnaDetail = (querys) => {
  return axios.get(`/api/v1/manager/qna/detail${querys}`);
};

export const postQnaRegister = (data) => {
  return axios.post(`/api/v1/manager/qna`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const putCloseQna = (param) => {
  return axios.put(`/api/v1/manager/qna/close${param}`);
};

export const productList = (querys) => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/${querys}`);
};

export const getOrderList = (querys) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/search${querys}`
  );
};

export const getOrderListExcel = (querys) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/search/excel${querys}`,
    {
      responseType: "blob",
    }
  );
};

export const getGoodsWithdrawalTotalWeight = (querys) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/summary${querys}`
  );
};

export const putGoodsWithdrawalStatus = (data) => {
  return axios.put(`/api/v1/manager/market/goodswithdrawal/trans/status`, data);
};

export const getGoodsWithdrawalConfirmCheck = (param) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/confirm${param}`
  );
};

export const getGoodsWithdrawalDeliveryExcel = (param) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/delivery/excel/${param}`,
    {
      responseType: "blob",
    }
  );
};

export const getProductDetail = (goodsId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/goods/item/${goodsId}`
  );
};

export const getWithdrawalAccountNumber = (id) => {
  return axios.get(`/api/v1/manager/adjustment/withdrawal/${id}/account`);
};

export const getOrderExcharge = (querys) => {
  return axios.get(`/api/v1/manager/adjustment/withdrawal/search${querys}`);
};

export const getOrderExchargeTotal = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/withdrawal/search/total${querys}`
  );
};

export const getOrderExchargeExcel = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/withdrawal/search/excel${querys}`,
    {
      responseType: "blob",
    }
  );
};

export const putexChargeStatus = (param) => {
  return axios.put(`/api/v1/manager/adjustment/withdrawal/status`, param);
};

export const getOrderSummary = () => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/search/summary`
  );
};

// 정산관리 > 정산현황 > 매출현황 리스트 조회
export const getSalesStatusList = (param) => {
  return axios.get(`/api/v1/manager/adjustment/salestax/search${param}`);
};

// 정산관리 > 정산현황 > 매출현황 리스트 엑셀 다운로드
export const getSalesStatusListExcel = (param) => {
  return axios.get(`/api/v1/manager/adjustment/salestax/search/excel${param}`, {
    responseType: "blob",
  });
};

// 정산관리 > 정산현황 > 매출현황 상태변경
export const getSalesStatusChange = (data) => {
  return axios.post(`/api/v1/manager/adjustment/salestax/modify`, data);
};

// 정산관리 > 매출수수료 > 매출 수수료 조회
export const getoutcomefeeSearch = (querys) => {
  return axios.get(`/api/v1/manager/adjustment/salesfee/search${querys}`);
};

// 정산관리 > 매출수수료 > 매출 수수료 합계
export const getoutcomefeeSummary = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/salesfee/search/summary${querys}`
  );
};

// 정산관리 > 매출수수료 > 매출 수수료 엑셀 조회
export const getoutcomefeeSearchExcel = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/salesfee/search/excel${querys}`,
    {
      responseType: "blob",
    }
  );
};
// 정산관리 > 매출수수료 > 매출 수수료 엑셀 조회
export const getoutcomefeeSearchCashExcel = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/salesfee/search/excel/cash${querys}`,
    {
      responseType: "blob",
    }
  );
};

// 정산관리 > 매출수수료 > 상태변경
export const patchOutcomeFeeStatus = (data) => {
  return axios.patch(`/api/v1/manager/adjustment/salesfee/status`, data);
};

// 정산관리 > 가맹점수수료 > 조회
export const getShopFeeList = (query) => {
  return axios.get(`/api/v1/manager/adjustment/shopfee/list${query}`);
};

// 정산관리 > 가맹점수수료 > 합계
export const getShopFeeSummary = (query) => {
  return axios.get(`/api/v1/manager/adjustment/shopfee/summary${query}`);
};

// 정산관리 > 가맹점수수료 > 엑셀다운로드
export const getShopFeeListExcel = (query) => {
  return axios.get(`/api/v1/manager/adjustment/shopfee/list/excel${query}`, {
    responseType: "blob",
  });
};

// 정산관리 > 대리점수수료 > 감면액 수정
export const postModifyDiscount = (param) => {
  return axios.post(`/api/v1/manager/adjustment/shopfee/discount`, param);
};

// 정산관리 > 가맹점수수료 > 상태변경
export const postModifyShopFee = (param) => {
  return axios.post(`/api/v1/manager/adjustment/shopfee/modify`, param);
};

// 감정평가 > 제품설정 > 제품 조회
export const getAppraisalProducts = (query = "") => {
  return axios.get(`/api/v1/manager/market/appraisal/Products${query}`);
};

// 감정평가 > 제품설정 > 제품 추가
export const postAppraisalProducts = (data) => {
  return axios.post(`/api/v1/manager/market/appraisal/Products`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// 감정평가 > 제품설정 > 제품 수정
export const putAppraisalProducts = (id, data) => {
  return axios.put(`/api/v1/manager/market/appraisal/Products/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// 감정평가 > 제품설정 > 제품 삭제
export const deleteAppraisalProducts = (data) => {
  return axios.delete(`/api/v1/manager/market/appraisal/Products`, {
    data,
  });
};

// 감정평가 > 처리현황 > 미처리 조회
export const getAppraisalRequestTodolist = (query) => {
  return axios.get(
    `/api/v1/manager/market/appraisal/Requests/todolist${query}`
  );
};

// 감정평가 순도별 중량 차감율 조회
export const getAppraisalWeightRates = (query = "") => {
  return axios.get(`/api/v1/manager/market/appraisal/WeightRates${query}`);
};

// 감정평가 > 감정현황 > 목록 조회
export const getAppraisalRequests = (query, isKorda = false) => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests${query}`);
};

// 감정평가 > 감정현황 > 목록 조회 엑셀다운로드
export const getAppraisalRequestsExcel = (query, isKorda = false) => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/excel${query}`, {
    responseType: "blob",
  });
};

// 감정평가 > 감정현황 > 중량합계 조회
export const getAppraisalTotalWeight = (query, isKorda = false) => {
  console.log(query);
  return axios.get(`/api/v1/manager/market/appraisal/Requests/summary${query}`);
};

// 감정평가 > 감정현황 > 승인대기, 반송대기 조회
export const getAppraisalWaiting = () => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/status/summary`);
};

// 감정평가 > 감정현황 > 상세 조회
export const getAppraisalRequestDetail = (id) => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/${id}`);
};

// 감정평가 > 감정현황 > 중량 합계 조회
export const getAppraisalRequestTotalWeight = (param) => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/summary${param}`);
};

// 감정평가 > 감정 상세 > 입고 마감일 수정
export const putAppraisalDeadline = (id, param) => {
  return axios.put(
    `/api/v1/manager/market/appraisal/Requests/${id}/deadline_stock_at`,
    param
  );
};

// 매수 매도 체결/미체결 조회
export const getTradeList = (status, querys) => {
  return axios.get(`/api/v1/manager/market/buysell/${status}/list${querys}`);
};

// 매수 매도 체결/미체결 엑셀 조회
export const getTradeListExcel = (status, querys) => {
  return axios.get(
    `/api/v1/manager/market/buysell/${status}/list/excel${querys}`,
    {
      responseType: "blob",
    }
  );
};

// 매수 매도 상하한가 변경
// export const getRefresh = () => {
//   return axios.get(`/api/v1/manager/market/buysell/refresh/min_max`);
// };

// 매수 매도 체결/미체결 합계
export const getTradeSummaryList = (status, querys) => {
  return axios.get(
    `/api/v1/manager/market/buysell/${status}/summary/list${querys}`
  );
};

// 감정평가 > 감정현황 > 상세 > 상태 변경
export const putAppraisalRequestStatus = (id, param, isKorda = false) => {
  return axios.put(
    `/api/v1/manager/market/appraisal/Requests/${id}/status`,
    param
  );
};

// 감정평가 > 감정현황 > 재고상태 변경
export const putAppraisalChangeStockStatus = (data) => {
  return axios.put(`/api/v1/manager/market/appraisal/Requests/stock`, data);
};

// 감정평가 > 감정현황 > 상세 > 상태 변경
export const deleteAppraisalRequestStatus = (id, isKorda = false) => {
  return axios.delete(`/api/v1/manager/market/appraisal/Requests/${id}/status`);
};

//감정평가 > 감정현황 > 제품수령 > 바코드조회
export const getAppraisalInventoryTake = (id) => {
  return axios.get(`/api/v1/manager/inventory/take/${id}`);
};

//감정평가 > 감정현황 > 제품수령 > 바코드수령
export const patchAppraisalInventoryTake = (data) => {
  return axios.patch(`/api/v1/manager/inventory/take`, data);
};

// 안심직거래 > 접수현황 summary 조회
export const getSafeTradeSummary = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/search/summary`);
};

// 안심직거래 > 상품 목록 조회
export const getSafeTradeProductList = (querys) => {
  return axios.get(`/api/v1/manager/market/safe-trade/search${querys}`);
};

// 안심직거래 > Filters 카테고리/ 상태 리스트 조회
export const getSafeTradeFitersList = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/search/list`);
};

// 안심직거래 > 상세 > 상품 상태 리스트 조회
export const getSafeTradeProductStatusList = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/status`);
};

// 안심직거래 > 상세 > 제품 접수 정보 조회
export const getSafeTradeReceptionInfo = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/order`);
};

// 안심직거래 > 상세 > 상품 이력 목록 조회
export const getSafeTradeProductHistoryList = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/history`);
};

// 안심직거래 > 상세 > 제품 감정 정보 조회
export const getSafeTradeAppraisalInfo = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}`);
};

// 안심직거래 > 상세 > 제품 등록 정보 조회
export const getSafeTradeRegisterInfo = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/item`);
};

// 안심직거래 > 상세 > 제품 카테고리 상세 조회
export const getSafeTradeCategoryDetail = (itemId, categoryId) => {
  return axios.get(
    `/api/v1/manager/market/safe-trade/item/${itemId}/category/${categoryId}`
  );
};

// 안심직거래 > 상세 > 제품 접수 정보 변경
export const postSafeTradeReceptionInfo = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/order`,
    data
  );
};

// 안심직거래 > 상세 > 제품 감정 정보 변경
export const postSafeTradeAppraisalInfo = (param, data) => {
  return axios.post(`/api/v1/manager/market/safe-trade/item/${param}`, data);
};

// 안심직거래 > 상세 > 제품 등록 정보 추가정보 삭제
export const deleteAdditionalInfo = (itemId, infoId) => {
  return axios.delete(
    `/api/v1/manager/market/safe-trade/item/${itemId}/info/${infoId}`
  );
};

// 안심직거래 > 상세 > 판매가 변경 이력 조회
export const getSalePriceHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/price`);
};

// 안심직거래 > 상세 > 판매기간 변경 이력 조회
export const getSaleDateHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/period`);
};

// 안심직거래 > 상세 > 재고위치 변경 이력 조회
export const getLocationHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/inventory`);
};

// 안심직거래 > 상세 > 관리자 메모 이력 조회
export const getAdminMemoHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/memo`);
};

// 안심직거래 > 상세 > 관리자 메모 등록
export const postRegisterAdminMemo = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/memo`,
    data
  );
};

// 안심직거래 > 상세 > 제품 등록 상품 이미지 등록
export const postRegisterImage = (itemId, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${itemId}/images`,
    data
  );
};

// 안심직거래 > 상세 > 제품 등록 상품 이미지 삭제
export const deleteRegisterImage = (itemId, imageId) => {
  return axios.delete(
    `/api/v1/manager/market/safe-trade/item/${itemId}/images/${imageId}`
  );
};

// 안심직거래 > 상세 > 제품 등록 상품 이미지 상태 변경
export const postChangeStatusImage = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/item/images`,
    data
  );
};

// 안심직거래 > 상세 > 제품 등록 정보 변경
export const postRegisterInfo = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/item`,
    data
  );
};

// 안심직거래 > 상세 > 상품 상태 업데이트 변경
export const putChangeStatus = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/item/${param}/status`,
    data
  );
};

// 안심직거래 > 인기상품 관리 > 인기상품 관리 조회
export const getDirectHotGoods = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/category/hot${param}`);
};

// 안심직거래 > 인기상품 관리 > 카테고리 목록 조회
export const getDirectCategoryList = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/category`);
};

// 안심직거래 > 인기상품 관리 > 인기상품 등록
export const postDirectHotGoodsCreate = (param) => {
  return axios.post(`/api/v1/manager/market/safe-trade/category/hot`, param);
};

// 안심직거래 > 인기상품 관리 > 인기상품 해제
export const deleteDirectHotGoods = (data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/category/hot/delete`,
    data
  );
};

// 안심직거래 > 인기상품 관리 > 인기상품 순서 변경
export const putDirectHotGoodsPosition = (param) => {
  return axios.put(`/api/v1/manager/market/safe-trade/category/hot`, param);
};

// 설정 > 수수료정책 > 분류관리 > 브랜드 관리
export const getDirectBrandList = (categoryId) => {
  return axios.get(
    `/api/v1/manager/market/safe-trade/category/detail/${categoryId}`
  );
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 등록
export const postDirectCategoryCreate = (data) => {
  return axios.post(`/api/v1/manager/market/safe-trade/category`, data);
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 수정
export const putDirectCategoryModify = (data, param) => {
  return axios.put(`/api/v1/manager/market/safe-trade/category/${param}`, data);
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 정렬 변경
export const putDirectCategorySort = (data) => {
  return axios.put(`/api/v1/manager/market/safe-trade/category`, data);
};

// 설정 > 수수료정책 > 분류관리 > 브랜드 정렬 변경
export const putDirectBrandSort = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/category/detail/${param}`,
    data
  );
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 등록
export const postDirectBrandCreate = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/category/detail/${param}`,
    data
  );
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 수정
export const putDirectBrandModify = (param, data, secondParam) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/category/detail/${param}/${secondParam}`,
    data
  );
};

// 설정 > 수수료정책 > 거래수수료 조회
export const getTradeFeeList = () => {
  return axios.get(`/api/v1/manager/buysell_policy/list`);
};

// 설정 > 수수료정책 > 거래수수료 수정
export const postTradeFees = (data) => {
  return axios.post(`/api/v1/manager/buysell_policy/modify`, data);
};

// 설정 > 수수료정책 > 수수료율 > 수수료 설정 조회
export const getDirectFeeSetting = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/category/fee`);
};

// 설정 > 수수료정책 > 수수료율 > 수수료 설정 상세 조회
export const getDirectFeeSettingDetail = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/category/fee/${param}`);
};

// 설정 > 수수료정책 > 수수료율 > 수수료 설정 수정
export const putDirectFeeSettingEdit = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/category/fee/${param}`,
    data
  );
};

// 설정 > 수수료정책 > 매칭 데이터 초기화
export const postResetMatching = (data) => {
  return axios.post(`/api/v1/manager/buysell_policy/resetmatching`, data);
};

// 설정 > 공휴일 설정 > 조회
export const getSettingHoliday = () => {
  return axios.get(`/api/v1/manager/holiday/list`);
};

// 설정 > 공휴일 설정 > 생성
export const createSettingHoliday = (data) => {
  return axios.post(`/api/v1/manager/holiday/create`, data);
};

// 설정 > 공휴일 설정 > 수정
export const modifySettingHoliday = (data) => {
  return axios.post(`/api/v1/manager/holiday/modify`, data);
};

// 고객센터 > 상품문의 > 상품 문의 조회
export const getDirectProductInquiry = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/qna${param}`);
};

// 고객센터 > 상품문의 > 상품 문의 상세 조회
export const getDirectProductInquiryDetail = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/qna/${param}`);
};

// 고객센터 > 상품문의 > 상품 문의 답변
export const postDirectProductInquiryAnswer = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/qna/${param}`,
    data
  );
};

// 고객센터 > 상품문의 > 상품 문의 현황 조회
export const getDirectProductInquirySummary = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/qna/summary`);
};

// // 안심직거래 > Filters 진행상태 조회
// export const getSafeTradeStatus = () => {
//   return axios.get(`/api/v1/manager/market/safe-trade/search/summary`);
// };

// 관리자 > 권한관리 > 권한 리스트 조회
export const getAuthorityList = () => {
  return axios.get(`/api/v1/manager/auth/authority`);
};

// 관리자 > 권한관리 > 권한 그룹 리스트 조회
export const getAuthorityGroupList = () => {
  return axios.get(`/api/v1/manager/auth/group`);
};

// 관리자 > 권한관리 > 권한 그룹 생성
export const postAuthorityGroup = (data) => {
  return axios.post(`/api/v1/manager/auth/group`, data);
};

// 관리자 > 권한관리 > 권한 그룹 상세 조회
export const getAuthorityGroupDetail = (id) => {
  return axios.get(`/api/v1/manager/auth/group/${id}`);
};

// 관리자 > 권한관리 > 권한 그룹 수정
export const postAuthorityGroupModify = (data) => {
  return axios.post(`/api/v1/manager/auth/group/modify`, data);
};

// 관리자 > 관리자관리 > 관리자 리스트 조회
export const getAdminList = (param) => {
  return axios.get(`/api/v1/manager/admin${param}`);
};

// 관리자 > 관리자관리 > SHOP 리스트 조회
export const getShopList = () => {
  return axios.get(`/api/v1/manager/shop/list`);
};

// 관리자 > 관리자관리 > 관리자 상태변경
export const patchAdminChangeStatus = (data) => {
  return axios.patch(`/api/v1/manager/admin`, data);
};

// 관리자 > 관리자관리 > 관리자 생성
export const postCreateAdmin = (data) => {
  return axios.post(`/api/v1/manager/admin`, data);
};

// 관리자 > 관리자관리 > 관리자 상세 조회
export const getAdminDetail = (id) => {
  return axios.get(`/api/v1/manager/admin/${id}`);
};

// 관리자 > 관리자관리 > 관리자 수정
export const putEditAdmin = (data) => {
  return axios.put(`/api/v1/manager/admin`, data);
};

// 관리자 > 관리자관리 > 관리자 아이디 중복확인
export const postCheckRedundancy = (data) => {
  return axios.post(`/api/v1/manager/admin/check`, data);
};

// 관리자 > 관리자관리 > 관리자 비밀번호 변경
export const putModifyPassword = (data) => {
  return axios.put(`/api/v1/manager/admin/password`, data);
};

// 관리자 > 내 정보
export const getAdminInfo = () => {
  return axios.get(`/api/v1/manager/info`);
};

// 관리자 > 내 정보 > 새 비밀번호 변경
export const putModifyMyPassword = (data) => {
  return axios.put(`/api/v1/manager/admin/my/password`, data);
};

export const putModifyConfirmPassword = (data) => {
  return axios.put(`/api/v1/manager/admin/confirm/password`, data);
};

// 관리자 > 내 정보 > 현재 비밀번호 확인
export const postRecentPasswordCheck = (data) => {
  return axios.post(`/api/v1/manager/verify/password`, data);
};

// 관리자 > 내 정보 > 내 정보 수정
export const putEditMyInfo = (data) => {
  return axios.put(`/api/v1/manager/info`, data);
};

// 관리자 환경설정 목록 조회
export const getConfigurationList = () => {
  return axios.get(`/api/v1/manager/envconfig/list`);
};

// 관리자 환경설정 설정
export const postConfiguration = (data) => {
  return axios.post(`/api/v1/manager/envconfig/modify`, data);
};

// 관리자 이벤트 목록 조회
export const getEventList = () => {
  return axios.get(`/api/v1/manager/event/list`);
};

// 관리자 매장자산 이력조회
export const getEventAssetHistoryList = (queryString) => {
  return axios.get(`/api/v1/manager/event/asset-history?${queryString}`);
};

// 관리자 이벤트 자산조회
export const getEventAsset = () => {
  return axios.get(`/api/v1/manager/event/asset`);
};

// 관리자 이벤트 자산발행
export const postEventAssetMint = (data) => {
  return axios.post(`/api/v1/manager/event/mint`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 관리자 이벤트 자산 조회
export const getAssetAmount = () => {
  return axios.get(`/api/v1/manager/event/asset`);
};

// 관리자 이벤트 자산 지급
export const postDropEventAsset = (data) => {
  return axios.post(`/api/v1/manager/event/payment`, data);
};

// 관리자 유저정보 조회
export const getUserList = (data) => {
  return axios.get(`/api/v1/manager/event/user${data}`);
};

// 관리자 통합거래내역 조회
export const getIntegrationHistory = (data) => {
  return axios.get(`/api/v1/manager/trade/user_log${data}`);
};

// 관리자 통합거래내역 엑셀 다운로드
export const getIntegrationHistoryDownload = (data) => {
  return axios.get(`/api/v1/manager/trade/user_log/down${data}`, {
    responseType: "blob",
  });
};

// 관리자 위탁거래내역 조회
export const getConsignmentHistory = (data) => {
  return axios.get(`/api/v1/manager/trade/brokerage${data}`);
};

// 관리자 위탁거래내역 엑셀 다운로드
export const getConsignmentHistoryDownload = (data) => {
  return axios.get(`/api/v1/manager/trade/brokerage/excel${data}`, {
    responseType: "blob",
  });
};

// 추천 매장 조회
export const getRecommendShopList = () => {
  return axios.get(`/api/v1/manager/shop/recommend-shop`);
};

// 추천 순서 변경
export const patchRecommendShopOrder = (data) => {
  return axios.patch(`/api/v1/manager/shop/recommend-shop`, data);
};

// 푸시 알림
export const postPush = (data) => {
  return axios.post(`/api/v1/manager/setting/push`, data);
};

// 품목 재질, 분류 조회
export const getInventoryItemCategory = () => {
  return axios.get("/api/v1/manager/inventory/item");
};

// 감정평가 > 검품및재질분류 > 상품 리스트 조회
export const getInventoryGoodsList = () => {
  return axios.get("/api/v1/manager/inventory/goods");
};

// 검품 및 재질 분류 목록 조회
export const getInventoryInspection = (param) => {
  // console.log(objToQueryStr(param));
  return axios.get(
    `/api/v1/manager/inventory/inspection${objToQueryStr(param)}`
  );
};

export const getInventoryStatusSummary = () => {
  return axios.get("/api/v1/manager/inventory/inspection/status/summary");
};

// 입고 요청
export const postInventoryInspection = (param) => {
  return axios.post(`/api/v1/manager/inventory/inspection`, param);
};

// 입고 취소 요청
export const postInventoryRequestCancel = (data) => {
  return axios.post(`/api/v1/manager/inventory/inspection/cancel`, data);
};

// 입고 취소 요청 승인
export const postInventoryCancel = (data) => {
  return axios.post(`/api/v1/manager/inventory/inspection/confirm`, data);
};

// 재고 현황 조회
export const getInventoryOrderPresent = () => {
  return axios.get(`/api/v1/manager/inventoryOrder/present`);
};

// 상품 재고 조회
export const getInventoryOrderGoods = (param) => {
  return axios.get(
    `/api/v1/manager/inventoryOrder/goods${objToQueryStr(param)}`
  );
};

// 상품 재고 엑셀
export const getInventoryOrderGoodsExcel = (param) => {
  return axios.get(
    `/api/v1/manager/inventoryOrder/goods/excel${objToQueryStr(param)}`,
    {
      responseType: "blob",
    }
  );
};

// 상품 재고 주문
export const postInventoryOrderGoodsRequest = (param) => {
  return axios.post(`/api/v1/manager/inventoryOrder/goods/request`, param);
};

// 보유 재고 변경
export const postInventoryOrderGoodsMove = (param) => {
  return axios.post(`/api/v1/manager/inventoryOrder/goods/move`, param);
};

// 상품 입고 목록 조회
export const getInventoryOrderStocks = (param) => {
  return axios.get(
    `/api/v1/manager/inventoryOrder/stocks${objToQueryStr(param)}`
  );
};

// 상품 입고 목록 엑셀 조회
export const getInventoryOrderStocksExcel = (param) => {
  return axios.get(
    `/api/v1/manager/inventoryOrder/stocks/excel${objToQueryStr(param)}`,
    { responseType: "blob" }
  );
};

// 상품 입고 목록 Summary 조회
export const getInventoryOrderSummarize = (param) => {
  return axios.get(
    `/api/v1/manager/inventoryOrder/summarize${objToQueryStr(param)}`
  );
};

// 상품 부분 입고이력 조회
export const getInventoryOrderHistory = (param) => {
  return axios.get(
    `/api/v1/manager/inventoryOrder/history${objToQueryStr(param)}`
  );
};

// 정련 대기 현황 조회
export const getInventoryRefineWait = (param) => {
  return axios.get(`/api/v1/manager/inventory/refine/wait${param}`);
};

// 정련 출고 조회
export const getInventoryRefineOut = (param) => {
  return axios.get(`/api/v1/manager/inventory/refine/out${param}`);
};

// 정렵 출고 저장
export const postInventoryRefineOut = (data) => {
  return axios.post(`/api/v1/manager/inventory/refine/out`, data);
};

// 정련 입고 조회
export const getInventoryRefineIn = (param) => {
  return axios.get(`/api/v1/manager/inventory/refine/in${param}`);
};

// 정련 입고 내역 조회
export const getInventoryRefineInHistory = (id) => {
  return axios.get(`/api/v1/manager/inventory/refine/in/history/${id}`);
};

// 정렵 입고 저장
export const postInventoryRefineIn = (data) => {
  return axios.post(`/api/v1/manager/inventory/refine/in`, data);
};

// 정렵 입고 수정
export const patchInventoryRefineIn = (data) => {
  return axios.patch(`/api/v1/manager/inventory/refine/modify`, data);
};

// 정련 책임 중량
export const postInventoryRefineInCharge = (data) => {
  return axios.post(`/api/v1/manager/inventory/refine/charge`, data);
};

// 기타입고 조회
export const getInventoryRefineEtc = (param) => {
  return axios.get(`/api/v1/manager/inventory/refine/etc${param}`);
};

// 기타입고 타입조회
export const getInventoryRefineEtcType = () => {
  return axios.get(`/api/v1/manager/inventory/refine/etc/type`);
};

// 기타입고 저장
export const postInventoryRefineEtc = (data) => {
  return axios.post(`/api/v1/manager/inventory/refine/etc`, data);
};

// 상품 입고 처리
export const postInventoryOrderStocksProcess = (param) => {
  return axios.post(`/api/v1/manager/inventoryOrder/stocks/process`, param);
};

// 재고 현황 조회 - 재고 유형별
export const getInventoryPresentByStockType = () => {
  return axios.get(`/api/v1/manager/inventory/present/stocktype`);
};

// 순도 목록 조회
export const getPurityType = () => {
  return axios.get(`/api/v1/manager/inventory/refine/purity-type`);
};

// 이벤트 입고 목록 조회
export const getInventoryEvent = (param) => {
  return axios.get(`/api/v1/manager/inventoryCoupon/search${param}`);
};

// 정렵 입고 저장
export const postInventoryStocked = (data) => {
  return axios.post(`/api/v1/manager/inventoryCoupon/stocked`, data);
};

// 이벤트 입고 자산 잔고 조회
export const getInventoryEventAsset = () => {
  return axios.get(`/api/v1/manager/event/asset`);
};

// 이벤트 입고 엑셀 조회
export const getInventoryExcel = (param) => {
  return axios.get(`/api/v1/manager/inventoryCoupon/search/excel${param}`, {
    responseType: "blob",
  });
};

// 재고관리 > 감정평가 수수료 > 감정평가 수수료 조회
export const getAppraisalFeeList = (params) => {
  return axios.get(`/api/v1/manager/disposal/search`, {
    params,
  });
};
// 재고관리 > 감정평가 수수료 > 자산처분
export const postCreateAppraisal = (data) => {
  return axios.post(`/api/v1/manager/disposal/create`, data);
};
/**  재고관리 > 감정평가 수수료 > 손익재고현황 조회*/
export const getAppraisalInventoryStatus = () => {
  return axios.get(`/api/v1/manager/inventory/present/stocktype`);
};
/** 재고관리 > 감정평가 수수료 > 감정평가 처분 승인 또는 반려  */
export const postAppraisalDisposalConfirm = (data) => {
  return axios.post(`/api/v1/manager/disposal/confirm`, data);
};

// 상품군 조회
export const getGoodsGroup = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/goods-group`);
};

// 상품군 등록
export const postGoodsGroup = (data) => {
  return axios.post(`/api/v1/manager/market/goodswithdrawal/goods-group`, data);
};

// 상품군 수정
export const patchGoodsGroup = (param, data) => {
  return axios.patch(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${param}`,
    data
  );
};

// 상품군 삭제
export const deleteGoodsGroup = (param) => {
  return axios.delete(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${param}`
  );
};

// 일반상품 조회
export const getGoods = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/goods-group/goods`);
};

// 상품군 상품 추가
export const postGoods = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${param}/goods`,
    data
  );
};

// 상품군 상품 제거
export const deleteGoods = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${param}/goods`,
    data
  );
};

// 순서변경
export const putGoodsSort = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${param}/goods/sort`,
    data
  );
};

// 상품군 이미지 추가
export const postGoodsImage = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${param}/image`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

// 상품군 이미지 삭제
export const deleteGoodsImage = (goodsGroupId, serviceImageId) => {
  return axios.delete(
    `/api/v1/manager/market/goodswithdrawal/goods-group/${goodsGroupId}/image/${serviceImageId}`
  );
};

// 상세 정보 이미지 조회
export const getMallImage = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/mall/image`);
};

// 상세 정보 이미지 저장
export const postMallImage = (data) => {
  return axios.post(`/api/v1/manager/market/goodswithdrawal/mall/image`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// 상세 정보 이미지 삭제
export const deleteMallImage = () => {
  return axios.delete(`/api/v1/manager/market/goodswithdrawal/mall/image`);
};

// 실물인출 > 제품관리 > 상품관리 : 상품 옵션 추가
export const postGoodsOption = (data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goods/options`,
    data
  );
};

// 실물인출 > 추가인출수량 저장
export const postAddDelivery = (data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/trans/addDelivery`,
    data
  );
};

/******************************************************************
 * 재고관리 > 재고 현황
 ******************************************************************/

// 재고관리 > 재고 현황 > 입출고 내역 목록
export const getInventoryPresentDaily = (param) => {
  return axios.get(
    `/api/v1/manager/inventory/present/daily${objToQueryStr(param)}`
  );
};

// 재고관리 > 재고 현황 > 입출고 내역 상세 조회
export const getInventoryPresentDailyDetail = (bizType, param) => {
  return axios.get(
    `/api/v1/manager/inventory/present/daily/detail/${bizType}${objToQueryStr(
      param
    )}`
  );
};

// 재고관리 > 재고 현황 > 입출고 내역 상세 엑셀 다운로드
export const getInventoryPresentDailyDetailExcel = (bizType, param) => {
  return axios.get(
    `/api/v1/manager/inventory/present/daily/detail/${bizType}/excel${objToQueryStr(
      param
    )}`,
    {
      responseType: "blob",
    }
  );
};

// 위탁매입 > 위탁시세 > KGE 위탁매입 시세 조회
export const getConsignmentKgePrice = (param) => {
  return axios.get(`/api/v1/manager/consignment/kge_price${param}`);
};

// 위탁매입 > 위탁시세 > 위탁매입 시세 조회
export const getConsignmentPrice = (param) => {
  return axios.get(`/api/v1/manager/consignment/price${param}`);
};

// 위탁매입 > 위탁시세 > 위탁매입 시세 등록
export const postConsignmentPrice = (data) => {
  return axios.post(`/api/v1/manager/consignment/price`, data);
};
