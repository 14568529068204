import React, { useState } from "react";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import Box from "@mui/material/Box";
import { makeStyles, Switch } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import routes from "../../libs/routes";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import cloneDeep from "lodash.clonedeep";
import { setQuickMenu } from "../../redux/quickMenu/QuickMenuReducer";
import { IoMdRemoveCircleOutline } from "react-icons/io";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const KORDA_SIDE_MENU = [
  { path: routes.managerAuthority, label: "권한관리" },
  { path: routes.managerManagement, label: "관리자관리" },
  { path: routes.myInfo, label: "내 정보" },
  { path: routes.memberNormal, label: "일반회원" },
  { path: routes.memberShop, label: "대리점회원" },
  // { path: routes.memberInactive, label: "사용정지회원" },
  // { path: routes.memberDelete, label: "휴면회원" },
  { path: routes.tradingAsset, label: "자산" },
  { path: routes.appraisalStatus, label: "감정현황" },
  { path: routes.appraisalProduct, label: "제품설정" },
  // { path: routes.appraisalProcess, label: "처리현황" },
  { path: routes.appraisalInspection, label: "검품 및 재질 분류" },
  { path: routes.consignmentPrice, label: "위탁 시세" },
  { path: routes.consignmentPurchaseStatus, label: "매입 현황" },
  { path: routes.physicalOrder, label: "통합주문현황" },
  { path: routes.physicalProduct, label: "제품관리" },
  { path: routes.directStatus, label: "접수/등록 현황" },
  { path: routes.directProduct, label: "상품관리" },
  { path: routes.directOrder, label: "주문관리" },
  { path: routes.settlementHistory, label: "정산현황" },
  { path: routes.settlementVirtualAc, label: "가상계좌관리" },
  { path: routes.settlementOutmoney, label: "출금관리" },
  { path: routes.settlementSalesFee, label: "매출수수료" },
  { path: routes.settlementShopFee, label: "가맹점수수료" },
  { path: routes.serviceNotice, label: "공지사항" },
  // { path: routes.serviceReview, label: "거래후기 관리" },
  { path: routes.serviceFaq, label: "FAQ 관리" },
  { path: routes.serviceFaqCategory, label: "FAQ 분류 관리" },
  // { path: routes.serviceUseGuide, label: "이용가이드 관리" },
  { path: routes.serviceInquiry, label: "고객문의관리" },
  { path: routes.serviceDirect, label: "상품 문의" },
  { path: routes.serviceNotification, label: "알림서비스" },
  // { path: routes.settingOrderPolicy, label: "거래정책" },
  // { path: routes.settingFee, label: "수수료정책" },
  { path: routes.settingPolicy, label: "이용약관" },
  // { path: routes.settingAdmin, label: "관리자 정보" },
  // { path: routes.settingLog, label: "접속기록" },
  // { path: routes.settingBasicInfo, label: "기본정보" },
];

const SHOP_SIDE_MENU = [
  { path: routes.tradingAsset, label: "자산" },
  { path: routes.appraisalStatus, label: "감정현황" },
  { path: routes.physicalOrder, label: "통합주문현황" },
  { path: routes.memberNormal, label: "일반회원" },
  { path: routes.shopInfo, label: "기본정보" },
  { path: routes.settlementShopFee, label: "가맹점수수료" },
];

const SettingPage = () => {
  const classes = useStyle();
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const sideMenu = isKorda ? KORDA_SIDE_MENU : SHOP_SIDE_MENU;
  const dispatch = useDispatch();
  const quickMenu = useSelector((s) => s.quickMenu);

  const [enabled, setEnabled] = useState(quickMenu?.enabled || false);

  const onChangeEnable = (e) => {
    const check = e.target.checked;
    const status = JSON.parse(localStorage.getItem("kumbangQuick"));
    localStorage.setItem(
      "kumbangQuick",
      JSON.stringify({ enabled: check, menu: [...status.menu] })
    );
    setEnabled(!enabled);
    dispatch(setQuickMenu(JSON.parse(localStorage.getItem("kumbangQuick"))));
  };

  const onChangeMenu = (value, index) => {
    const status = JSON.parse(localStorage.getItem("kumbangQuick"));
    let menu = cloneDeep(status.menu);
    menu[index] = {
      id: index,
      label: value?.label || "",
      path: value?.path || "",
      check: true,
    };
    localStorage.setItem(
      "kumbangQuick",
      JSON.stringify({ ...status, menu: [...menu] })
    );
    dispatch(setQuickMenu(JSON.parse(localStorage.getItem("kumbangQuick"))));
  };

  const onClickRemoveMenu = (index) => {
    const status = JSON.parse(localStorage.getItem("kumbangQuick"));
    let menu = cloneDeep(status.menu);
    menu[index] = {
      id: index,
      label: "",
      path: "",
      check: false,
    };
    localStorage.setItem(
      "kumbangQuick",
      JSON.stringify({ ...status, menu: [...menu] })
    );
    dispatch(setQuickMenu(JSON.parse(localStorage.getItem("kumbangQuick"))));
  };

  const menuArr = ["", "", "", "", ""];
  return (
    <>
      <PageLabel>기본 설정</PageLabel>
      <Flex className={classes.root}>
        <Box
          sx={{
            width: "100%",
            height: 500,
          }}
          style={{ display: "flex", justifyContent: "center" }}>
          <table className={classes.tableAttr}>
            <tbody>
              <tr>
                <th>퀵메뉴 활성여부</th>
                <td>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={enabled}
                    onChange={onChangeEnable}
                  />
                </td>
              </tr>
              {menuArr.map((_, index) => {
                return (
                  <tr key={`menu-${index}`}>
                    <th>{index + 1}번째 메뉴</th>
                    <td>
                      <Autocomplete
                        options={sideMenu}
                        sx={{ width: 300, marginLeft: "20px" }}
                        getOptionLabel={(option) => option.label}
                        onChange={(_, newValue) => {
                          onChangeMenu(newValue, index);
                        }}
                        id={`${index}Menu`}
                        disableClearable={false}
                        renderInput={(params) => {
                          return (
                            <TextField
                              id={`${index}Input`}
                              {...params}
                              label={
                                quickMenu?.["menu"]?.[index]?.label ||
                                "등록하실 메뉴를 선택해주세요."
                              }
                            />
                          );
                        }}
                      />
                      &nbsp;
                      {quickMenu?.["menu"]?.[index]?.label &&
                        !quickMenu?.["menu"]?.[index]?.check && (
                          <IoMdRemoveCircleOutline
                            style={{ fontSize: "30px" }}
                            onClick={() => onClickRemoveMenu(index)}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Flex>
    </>
  );
};

const useStyle = makeStyles({
  root: {
    height: "700px",
    display: "flex",
    alignItems: "flex-start",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableAttr: {
    width: "90%",
    height: "90%",
    "& th": {
      borderRadius: "5px",
      backgroundColor: "rgba(224, 224, 224, 1)",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "normal",
      width: "30%",
    },
    "& td": {
      border: "1px solid rgba(213, 213, 213, 0.87)",
      borderRadius: "5px",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
});

export default SettingPage;
